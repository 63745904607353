import React from 'react';

const SingleGif = (image) => {
    return (
        <div className="gif">
            <img src={image.gif.images.downsized.url} />
        </div>
    )
};

export default SingleGif;