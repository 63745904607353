import React from 'react';
import SingleGif from './SingleGif';
import Card from '@material-ui/core/Card';

const AllGifs = (props) => {
    const gifItems = props.gifs.map((image) => {
        return <SingleGif key={image.id} gif={image} />
    });

    return (
        <Card>
            {gifItems}
        </Card>

    );
};

export default AllGifs;