import React from 'react';
import ReactDOM from 'react-dom';
import Search from './search';
import AllGifs from "./AllGifs";
import request from 'superagent';
import './index.css';
import Button from '@material-ui/core/Button';

class App extends React.Component {
    constructor() {
        super();

        this.state = {
            gifs: []
        };
        this.offset = 0;
        this.term = '';

        window.addEventListener('scroll', () => {
            if ((window.innerHeight + window.scrollY) > document.body.offsetHeight) {
                this.offset += 20;
                this.handleSearch(this.term, true);
            }
        });
    }

    handleSearch = (term, more) => {
        document.getElementById('loadMore').style.display="block";
        this.term = term;
        this.url = `http://api.giphy.com/v1/gifs/search?q=${this.term.replace(/\s/g, '+')}&offset=${this.offset}&limit=20&api_key=e5AuCyrfwzVlHK8BOwFJv5lr0eRjB5b7`;
        request.get(this.url, (err, res) => {
            if (more === true) {
                this.setState( (state) => {
                    state.gifs = state.gifs.concat(res.body.data);
                    return state;
                });
            } else {
                this.setState({gifs: res.body.data});
            }
        });
    };

    handleLoadMore = () => {
        this.offset += 20;
        this.handleSearch(this.term, true);
    };

    render() {
        return (
            <div>
                <Search onSearch={this.handleSearch} />
                <div className="listing">
                    <AllGifs gifs={this.state.gifs} />
                    <Button onClick={this.handleLoadMore} id="loadMore" variant="outlined" color="primary">Load more</Button>
                </div>
            </div>
        );
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
