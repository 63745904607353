import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class Search extends React.Component {
    constructor() {
        super();
        this.state = { inputValue: '' };
        this.onSearch = this.onSearch.bind(this);
    }

    onSearch = e => {
        e.preventDefault();
        let inputText = this.state.inputValue;
        this.setState({inputText});
        this.props.onSearch(inputText);
    };

    updateInputValue = (evt) =>  {
        this.setState({
            inputValue: evt.target.value
        });
    };

    render() {
        return (
            <div className="search">
                <form onSubmit={this.onSearch}>
                    <TextField
                        id="input-field"
                        label="Search term..."
                        margin="normal"
                        value={this.state.inputValue}
                        onChange={this.updateInputValue}/>

                    <Button variant="outlined" color="primary" type="submit" id="button">
                        Search
                    </Button>
                </form>
            </div>
        );
    }
}

export default Search;